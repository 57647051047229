.baseLayout{
    min-width: 400px;
    overflow: hidden;
    width: 100vw;
    max-width: 100vw;
}
.base-inner-header{
    position: relative;
    color : white;
    top: 0;
    left : 0;
    right: 0;
    min-height: 2.5rem;
    background-color: #A5001E;
}


.base-header{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    max-height: 120px;
    font-size: 16px;
}
@media (max-width: 1024px) {
    .base-header {
        flex-direction: column;
    }
    .logoContainer{
        max-width: 25vw;
    }

    .logo{
        max-width: 80%;
    }

    .lower-link-container{
        margin-left: -3%!important;
        margin-right: 1%!important;

    }
    .lower-link-container>a{
        margin: 0 0.5rem

    }
}
.link-container{
    position: relative;
    right: 10%;
    display: inline-flex;
    float: right;
    margin-top: 0.5rem;
    width: 30%;
    max-width: 550px;
    flex-direction: row;
    justify-content: space-between;
}


.lower-link-container{
    display: inline-flex;
    margin: auto 5%;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    color: #808080a1;
    font-family: Cambria, sans-serif;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: visible;
}

.lower-link-container-mobile{

    width: 100%;
    font-size: 1.25rem;
    font-weight: 600;
    color: #808080a1;
    font-family: Cambria, sans-serif;
    margin: auto 5% auto -5%;

}

.lowerHeader{
    display: flex;
    justify-content: left;

}

.logoContainer{
    float: left;
    width: 30vw;
    min-width: 360px;
}

.logo{
    width: 100%;
    min-width: 340px;
    max-height: 120px;
    margin-bottom: -1rem;
}


:root {
    --main-container-width: 70%;
    --footer-height: 100px;
    min-width: 100vw;
}
.sideContainer{
    width: calc((100% - var( --main-container-width))/2);
}

.mainContainer{
    width: 100vw;
    min-width: 400px;
    min-height: calc(100vh - 220px);
    display: flex;
    padding-bottom: 2rem;
}

.mainContentContainer{
    min-width: var( --main-container-width);
    width: var( --main-container-width);
}

.footer{
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #A5001E;
    min-height: var(--footer-height);
    min-width: 400px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    padding: 2rem;
    color: white;
}

.footerLinks{
    display: inline-flex;
    margin: auto 0;

}
.footerLinks > :not(:first-child){
    margin-left: 2rem;
}

.baseLayoutLinks{
    color: inherit!important;
    text-decoration: inherit!important;
}

.fadeInnLinks{
    /*transition: width 0.5s;*/
}

.layoutDivider{
    min-height: calc(100vh - var(--footer-height))
}


* {
    /*border:1px solid red !important;*/
}
