.carouselContainer{
    /*min-height: 25rem;*/
    /*height: 100%;*/
    overflow-y: visible;
    overflow-x: clip;
}

.carouselContainer > ul {
    max-height: 200px;
}
.expandableWrapper{
    position: relative;
    box-shadow: 0 0 10px 1.5px gray;
    border-radius: 8px;
    /*padding: 0.75rem;*/
    height: 200px;
    /*max-height: 190px;*/
    overflow: hidden;
    transition: all 0.2s ease-in;
    background-color: rgb(147, 222, 195);
}

.carouselWrapper{
    margin-top: 2rem;
    width: 103%;
    position: relative;
    margin-bottom: 200px;
}

.expandableWrapperText{
    opacity: 0;
}
.expandableWrapperTextExpanded{
    opacity: 1;
    transition: opacity 0.2s ease-in;
    transition-delay: 0.2s;
}

.expandableWrapperExpanded{
    height: auto;
    max-height: 100%;
}
.expandable{
    overflow: hidden;
    transition: all 0.2s ease-out;
    height: auto;
    flex: 1;
    /*background-color: green;*/
    background-color: white;
    padding: 1rem;

}

.expandableCollapsed{
    flex: 0;
    /*background-color: #BBDAB1;*/
    /*padding: 0.5rem;*/
}

.title{
    font-weight: bold;
    height: 60px;
    padding: 1rem;
    margin-bottom: 10px;
    /*font-size: 1.5rem;*/
}

.timePeriod{
    font-style: italic;
    font-weight: bold;
    height: 60px;
    padding: 0.5rem;
}

.cardImage{
    height: 50%;
    width: 100%;
    /*margin-right: -1rem;*/
}
.cardImageWrapper{
    position: relative;
    background-color: white;
    left: 0;
    right: 0;
    top: 0;
    height: 130px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.carouselLine{
    position: absolute;
    top: 25%;
    left: -25%;
    right: -25%;
    height: 50%;
    background-color: #A5001E;
}
