.header-mainpage{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
}

.contentFader.show{
    opacity: 1;
    transition: opacity 2s;
}

.contentFader.hide{
    opacity: 0;
    transition: opacity 0.75s;
}


