.header-mainpage{
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 2rem;
}

.contentFader.show{
    opacity: 1;
    transition: opacity 0.75s;
}

.contentFader.hide{
    opacity: 0;
    /*transition: opacity 0.5s;*/
}

.titleWrapper{
    text-align: center;
    margin-bottom: 3rem;
}

.waveContainer{
    left: -2vw;
    width: 140vw;
    height: 100px;
    overflow: hidden;
    position: relative;
    bottom: -400px;
    stroke-width: 0.5rem;
    color: #A5001E;
}

.divider{
    min-height: 2px;
    box-shadow: 0 1px lightgrey;
    margin: 2rem;
}
.heroImage{

    height: 100%;
    width: max-content;
    position: absolute;
    min-height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}

.heroImgImg{
    height: 500px;
    opacity: 0%;
}

.hero-image-container{
    width: 106vw;
    min-width: 400px;
    position: relative;
    left: -22%;
    height: 50%;
    min-height: 500px;
    border: solid white;
    background-position: 0 15%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    background-color: black;

}
